import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs"
import HeroSimple from "../components/hero/heroSimple/heroSimple"
import PageBuilder from "../components/pageBuilder/pageBuilder"
import Seo from "../components/seo/seo"

const Page = ({
  data: {
    sanityPage: {
      tabs: { content: page, seo },
    },
  },
}) => {
  const parent = page.parent?.tabs?.content
  const steps = [
    {
      link: parent?.slug?.current,
      title: parent?.title,
    },
  ]

  return (
    <Layout>
      <Seo title={page.title} {...seo} breadcrumbs={parent ? steps : []} />
      {parent ? <Breadcrumbs steps={steps} /> : null}
      <HeroSimple blocks={page._rawHero[0].children} small={true} />
      <PageBuilder pageBuilder={page.pageBuilder} />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    sanityPage(tabs: { content: { slug: { current: { eq: $slug } } } }) {
      tabs {
        content {
          title
          _rawHero
          pageBuilder {
            ...PageBuilder
          }
          parent {
            tabs {
              content {
                title
                slug {
                  current
                }
              }
            }
          }
        }
        seo {
          canonical
          description
          metaTitle
          noIndex
          openGraphImage {
            asset {
              url
            }
          }
          removeSitemap
        }
      }
    }
  }
`

export default Page
